// src/components/IsArticleHelpful.js

import React, { useState } from "react"
import ContactForm from "./ContactForm"

const IsArticleHelpful = () => {
  const [isShowForm, setIsShowForm] = useState(null)

  const handleYesClick = () => {
    // setIsShowForm(false)
  }

  const handleNoClick = () => {
    setIsShowForm(true)
  }

  return (
    <div>
      {isShowForm === null && (
        <div className="bg-[#0A1E46] py-16 px-4 rounded-[10px] mt-16">
          <h2 className="text-[24px] md:text-[32px] leading-[1.2em] font-[500] text-white mb-6 text-center">
            Was this article helpful?
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 mt-8">
            <button
              type="button"
              className="primary-transparent-btn block font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] text-center py-3 transition duration-300 !border !text-white !border-white min-w-[120px]"
              onClick={handleNoClick}
            >
              No
            </button>
            <button
              type="button"
              className="primary-btn block font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] text-center py-3 transition duration-300 !border !bg-white !text-[#0A1E46] min-w-[120px]"
              onClick={handleYesClick}
            >
              Yes
            </button>
          </div>
        </div>
      )}

      {isShowForm && (
        <div className="mt-16">
          <div className="bg-[#0A1E46] py-16 px-5 md:px-10 rounded-[10px] mt-16">
            <h2 className="text-[24px] md:text-[32px] leading-[1.2em] font-[500] text-white mb-6">
              Submit a Request
            </h2>
            <ContactForm formType={"articlehelp"} buttonText={"Submit Request"} buttonEC={"bg-transparent !border !border-white hover:bg-white"} />
          </div>
        </div>
      )}
    </div>
  )
}

export default IsArticleHelpful
