import React from "react";
import { stripHtmlTags } from "../../utils/utils";


const generateId = (title) => {
  const strippedTitle = stripHtmlTags(title);
  return strippedTitle.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
};

const InnerPageHeader = ({ title, titleEC, description, descriptionEC }) => {
  return (
    <div>
      <h1
        id={generateId(title)}
        className={`text-[28px] md:text-[40px] lg:text-[58px] leading-[1.2em] font-[700] capitalize max-w-[1100px] w-[100%] ${titleEC}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className={`text-[18px] leading-[1.4em] mt-6 mb-8 ${descriptionEC}`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default InnerPageHeader;
