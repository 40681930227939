import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import LinkComponent from "../atoms/Link"

const AccordionItem = ({ title, titleSlug, links }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      toggleAccordion()
    }
  }

  return (
    <div>
      <div
        tabIndex={0}
        aria-expanded={isOpen}
        className="flex justify-between items-center gap-2 text-[18px] leading-[1em] text-[#0A1E46]"
      >
        <LinkComponent to={titleSlug + "/"} text={title} ec={`text-[#0A1E46] !leading-[1.4em]`} />
        <FontAwesomeIcon
          onClick={toggleAccordion}
          onKeyDown={handleKeyDown}
          className={`cursor-pointer`}
          icon={isOpen ? faAngleUp : faAngleDown}
        />
      </div>
      {isOpen && (
        <div>
          <ul className="list-none pl-5 flex flex-col gap-3 pt-5">
            {links.map((link, index) => (
              <li key={index}>
                <LinkComponent
                  to={link.url}
                  text={link.text}
                  ec={`!text-[16px] !leading-[1.4em] text-[#0A1E46] cursor-pointer hover:underline`}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default AccordionItem
