import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({componentEC, placeholder, onChange, value}) => {
  return (
    <div className={`max-w-[600px] w-[100%] flex items-center px-4 py-3 gap-3 border border-[#C4C2C2] rounded-[50px] ${componentEC}`}>
      <FontAwesomeIcon icon={faSearch} className="text-[#0A1E46] text-[20px]" />
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className="!border-[0] !outline-[0] placeholder-[#979797] w-full"
      />
    </div>
  );
}

export default SearchBox;
