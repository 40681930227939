import React, { useState } from "react";
import SearchBox from "../molecules/SearchBox";
import Accordion from "../molecules/Accordian";
import { articles } from "../../Content/Articles";
import { stripHtmlTags } from "../../utils/utils";
import { articlesPostTypeSlug } from "../../Content/Global";

// Transform articles to sidebarLinks array
const sidebarLinks = articles.map(article => ({
  title: stripHtmlTags(article.title),
  titleSlug: articlesPostTypeSlug + article.slug,
  links: article.contentSections
    .filter(section => section.title) // Filter out sections without a title
    .map(section => ({
      text: section.title,
      url: articlesPostTypeSlug + `${article.slug}#${section.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '')}`
    }))
}));

const SidebarWithLinksAndSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");

  // Handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter links based on search query (Maximum 8 items to avoid scrollbar)
  const filteredLinks = sidebarLinks
    .filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.links.some(link => link.text.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .slice(0, 8);

  return (
    <div className="sticky top-[40px] border rounded-[25px] px-6 py-8">
      <h3 className="text-[24px] leading-[1.4em] font-[500] mb-6">Articles</h3>
      <SearchBox placeholder={"Search Anything"} onChange={handleSearchChange} value={searchQuery} />
      
      {filteredLinks.length === 0 ? (
        <p className="text-[16px] text-[#666666] py-4">No matching articles found.</p>
      ) : (
        <Accordion items={filteredLinks} />
      )}
    </div>
  );
};

export default SidebarWithLinksAndSearch;
