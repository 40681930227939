import React, { useEffect, useState } from "react";
import ContentSection from "../../molecules/ContentSection";
import InnerPageHeader from "../../molecules/InnerPageHeader";
import { articles } from "../../../Content/Articles";

const Content = ({ slug }) => {
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    const article = articles.find(post => post.slug === slug);
    if (article) {
      setSelectedArticle(article);
    }
    // Cleanup function (optional)
    return () => setSelectedArticle(null);
  }, [slug]);


  if (!selectedArticle) {
    return <div className="synkli-section--horizontal-space ">No Article found with such slug!</div>; // or handle this appropriately
  }

  return (
    <div>
      <InnerPageHeader
        title={selectedArticle.title}
        description={selectedArticle.description}
      />

      {selectedArticle.contentSections.map((section, index) => (
        <ContentSection
          key={index}
          title={section.title}
          description={section.description}
          list={section.list}
          image={section.image}
          imageEC={section.imageEC}
        />
      ))}
    </div>
  );
};

export default Content;
